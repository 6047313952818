import React, { PureComponent, ChangeEvent, ReactEventHandler } from "react";
import { connect } from "react-redux";

import { SelectElement } from "components/SelectElement/SelectElement";
import { setViewMode, setOfflineMode, setTheme } from "actions/ConfigActions";
import { updateVariantAction, resetVariants } from "actions/variantActions";
import { ConfigInterface, Themes, ThemeTypes } from "reducers/configReducer";
import { StateInterface } from "reducers/rootReducer";
import { VariantState } from "reducers/variantReducer";
import { setQueryParams } from "libs/utils";

interface PropsInterface extends MapPropsInterface {
  setViewMode: typeof setViewMode;
  setOfflineMode: typeof setOfflineMode;
  setTheme: typeof setTheme;
  updateVariantAction: typeof updateVariantAction;
  resetVariants: typeof resetVariants;
}

class DebugView extends PureComponent<PropsInterface> {
  private toggleOffline = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;
    const { setOfflineMode } = this.props;

    setOfflineMode(checked);
  };

  private handleVariantChange = (
    event: ChangeEvent<HTMLSelectElement>
  ): void => {
    const { name, value } = event.target;
    const { updateVariantAction } = this.props;
    updateVariantAction(name, value);
  };

  private clearVariants = (): void => {
    this.props.resetVariants();
    setQueryParams({});
  };

  private buildVariants(variants: VariantState): JSX.Element[] {
    return Object.entries(variants).map(
      ([key, val]): JSX.Element => (
        <p key={key}>
          {val.title}
          <SelectElement {...val} handleChange={this.handleVariantChange} />
        </p>
      )
    );
  }

  private handleThemeChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    const { setTheme } = this.props;
    const { value } = event.target;
    setTheme(value as ThemeTypes);
  };

  public render(): JSX.Element {
    const { isOffline, variants, theme } = this.props;
    const variantControls = this.buildVariants(variants);

    return (
      <main>
        <h1>Debug view</h1>
        <p>Customize app config and view status information.</p>

        <h2>Config</h2>

        <label htmlFor="theme">Theme</label>
        <select
          defaultValue={theme}
          id="theme"
          onChange={this.handleThemeChange}
        >
          {Object.keys(Themes).map(
            (k: string): JSX.Element => {
              return (
                <option key={k} value={k}>
                  {k}
                </option>
              );
            }
          )}
        </select>

        <label htmlFor="offlineInput">Is offline</label>
        <input
          checked={isOffline}
          id="offlineInput"
          name="toggleOffline"
          onChange={this.toggleOffline}
          type="checkbox"
        />

        <h2>Variants</h2>
        <button onClick={this.clearVariants}>Clear variants</button>
        {variantControls}
      </main>
    );
  }
}

interface MapPropsInterface
  extends Pick<ConfigInterface, "isOffline" | "theme">,
    Pick<StateInterface, "variants"> {}

function mapStateToProps(state: StateInterface): MapPropsInterface {
  return {
    variants: state.variants,
    isOffline: state.config.isOffline,
    theme: state.config.theme
  };
}

export default connect(
  mapStateToProps,
  {
    setViewMode,
    setOfflineMode,
    updateVariantAction,
    resetVariants,
    setTheme
  }
)(DebugView);
