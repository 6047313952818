import React, { PureComponent } from "react";
import { FiUser, FiSettings, FiArrowLeft } from "react-icons/fi";
import { List, WhiteSpace, Tabs, Button, Accordion, Switch } from "antd-mobile";

import "./SettingsView.css";
import DebugView from "views/DebugView";

const { Item } = List;
const { Brief } = Item;
const { Panel } = Accordion;

interface PropsInterface {
  activeTabIndex: number;
  pushNotifications: boolean;
  breakingNewsNotification: boolean;
  myActivityNotifications: boolean;
}

export class SettingsView extends PureComponent<{}, PropsInterface> {
  public constructor(props: PropsInterface) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      pushNotifications: true,
      breakingNewsNotification: true,
      myActivityNotifications: true
    };
  }

  private setTabIndex = (index: number): void => {
    this.setState({ activeTabIndex: index });
  };

  public render(): JSX.Element {
    const {
      activeTabIndex,
      pushNotifications,
      breakingNewsNotification,
      myActivityNotifications
    } = this.state;

    return (
      <section className="settings-view">
        <Tabs
          page={activeTabIndex}
          prerenderingSiblingsNumber={0}
          renderTabBar={false}
          swipeable={false}
          tabs={[
            { title: "settings" },
            { title: "account" },
            { title: "Config" }
          ]}
        >
          <div>
            <List className="settings-view__list">
              <Item
                arrow="horizontal"
                className="settings-view__item"
                onClick={(): void => {
                  this.setTabIndex(1);
                }}
                thumb={<FiUser />}
              >
                My Account
                <Brief className="settings-view__item-subtitle">
                  Review details
                </Brief>
              </Item>

              <Item
                arrow="horizontal"
                className="settings-view__item"
                onClick={(): void => {
                  this.setTabIndex(2);
                }}
                thumb={<FiSettings />}
              >
                Configuration
                <Brief className="settings-view__item-subtitle">
                  Prototype settings
                </Brief>
              </Item>
            </List>

            <WhiteSpace size="xl" />
            <Accordion>
              <Panel header="Notifications test">
                <List className="my-list">
                  <List.Item
                    extra={
                      <Switch
                        checked={pushNotifications}
                        onChange={(): void => {
                          this.setState({
                            pushNotifications: !pushNotifications
                          });
                        }}
                        platform="android"
                      />
                    }
                  >
                    Push notifications
                  </List.Item>

                  <List.Item
                    extra={
                      <Switch
                        checked={breakingNewsNotification}
                        onChange={(): void => {
                          this.setState({
                            breakingNewsNotification: !breakingNewsNotification
                          });
                        }}
                        platform="android"
                      />
                    }
                  >
                    Breaking news
                  </List.Item>

                  <List.Item
                    extra={
                      <Switch
                        checked={myActivityNotifications}
                        onChange={(): void => {
                          this.setState({
                            myActivityNotifications: !myActivityNotifications
                          });
                        }}
                        platform="android"
                      />
                    }
                  >
                    My Activity
                  </List.Item>
                </List>
              </Panel>

              <Panel header="Accessibility">
                <section className="settings-view__panel--pad">
                  <p>Accessibility Options</p>
                  <p>Accessibility Options</p>
                  <p>Accessibility Options</p>
                  <p>Accessibility Options</p>
                </section>
              </Panel>

              <Panel header="Support">
                <section className="settings-view__panel--pad">
                  <p>support information</p>
                  <p>support information</p>
                  <p>support information</p>
                  <p>support information</p>
                </section>
              </Panel>

              <Panel header="About">
                <section className="settings-view__panel--pad">
                  <p>All about the telegraph app</p>
                  <p>All about the telegraph app</p>
                  <p>All about the telegraph app</p>
                  <p>All about the telegraph app</p>
                  <p>All about the telegraph app</p>
                </section>
              </Panel>
            </Accordion>
          </div>

          {/* TAB 2 */}
          <div>
            <List className="settings-view__list">
              <Item>
                <Button
                  icon={<FiArrowLeft />}
                  inline={true}
                  onClick={(): void => {
                    this.setTabIndex(0);
                  }}
                  size="small"
                  style={{
                    marginRight: "4px",
                    fontSize: "12px",
                    padding: "0 10px"
                  }}
                  type="ghost"
                >
                  Back
                </Button>
              </Item>
              <Item
                arrow="empty"
                className="settings-view__item"
                onClick={(): void => {}}
                thumb={<FiUser />}
              >
                Adam Reader
                <Brief className="settings-view__item-subtitle">
                  Logged in as a_reader22@example.com
                </Brief>
              </Item>
            </List>
            <Accordion>
              <Panel header="Personal details">
                <section className="settings-view__panel--pad">
                  Personal detail information
                </section>
              </Panel>

              <Panel header="Subscription details">
                <section className="settings-view__panel--pad">
                  Subscription details
                </section>
              </Panel>

              <Panel header="My Newsletters">
                <section className="settings-view__panel--pad">
                  My Newsletters
                </section>
              </Panel>

              <Panel header="My Rewards">
                <section className="settings-view__panel--pad">
                  My Rewards
                </section>
              </Panel>
            </Accordion>
          </div>

          {/* Tab 3 */}

          <List className="settings-view__list">
            <Item>
              <Button
                icon={<FiArrowLeft />}
                inline={true}
                onClick={(): void => {
                  this.setTabIndex(0);
                }}
                size="small"
                style={{
                  marginRight: "4px",
                  fontSize: "12px",
                  padding: "0 10px"
                }}
                type="ghost"
              >
                Back
              </Button>
            </Item>
            <Item>
              <DebugView />
            </Item>
          </List>
        </Tabs>
      </section>
    );
  }
}
