import React from "react";
import { NavBar, Pagination, Tabs } from "antd-mobile";
import { ArticleType } from "reducers/articleReducer";
import Article from "components/Article/Article";
import "./ArticleCollectionView.css";

interface PropTypes {
  articles: ArticleType[];
  activeArticle: string | null;
  articleIndex: number;
}

export function ArticleCollectionView(props: PropTypes): JSX.Element | null {
  const { articles, articleIndex, activeArticle } = props;

  if (!activeArticle) return null;

  const articleCount = articles.length;
  const articleTabs = articles.map((article): { title: string } => ({
    title: article.id
  }));

  const articleEls = articles.map(
    (article): JSX.Element => <Article id={article.id} key={article.id} />
  );

  return (
    <section className="article_collection">
      <NavBar
        leftContent={<button onClick={(): void => history.back()}>Back</button>}
        rightContent={
          articleCount > 1 && (
            <div className="article_collection__pagination">
              Page
              <Pagination
                current={articleIndex + 1}
                mode="number"
                total={articleCount}
              />
            </div>
          )
        }
      />

      <Tabs
        destroyInactiveTab={true}
        initialPage={articleIndex}
        onChange={(tabInfo): void => history.pushState(null, "", tabInfo.key)}
        renderTabBar={false}
        tabs={articleTabs}
      >
        {articleEls}
      </Tabs>
    </section>
  );
}
