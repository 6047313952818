import React from "react";
import { Card, WingBlank, WhiteSpace, Carousel, List } from "antd-mobile";
import "./ActivityView.css";

const { Item } = List;

export const ActivityView = (): JSX.Element => {
  // Notifications
  // - see all
  // History
  // - see all
  // Saved
  // - see all

  const savedData = [
    {
      id: "xx",
      title:
        "Theresa May calls on Vladimir Putin to halt irresponsible activity around the world",
      thumb:
        "https://www.telegraph.co.uk/content/dam/PortalPictures/June2019/mayputin858-small.jpg?imwidth=120"
    },
    {
      id: "xx",
      title:
        "Theresa May calls on Vladimir Putin to halt irresponsible activity around the world",
      thumb:
        "https://www.telegraph.co.uk/content/dam/PortalPictures/June2019/mayputin858-small.jpg?imwidth=120"
    },
    {
      id: "xx",
      title:
        "Theresa May calls on Vladimir Putin to halt irresponsible activity around the world",
      thumb:
        "https://www.telegraph.co.uk/content/dam/PortalPictures/June2019/mayputin858-small.jpg?imwidth=120"
    },
    {
      id: "xx",
      title:
        "Theresa May calls on Vladimir Putin to halt irresponsible activity around the world",
      thumb:
        "https://www.telegraph.co.uk/content/dam/PortalPictures/June2019/mayputin858-small.jpg?imwidth=120"
    },
    {
      id: "xx",
      title:
        "Theresa May calls on Vladimir Putin to halt irresponsible activity around the world",
      thumb:
        "https://www.telegraph.co.uk/content/dam/PortalPictures/June2019/mayputin858-small.jpg?imwidth=120"
    },
    {
      id: "xx",
      title:
        "Theresa May calls on Vladimir Putin to halt irresponsible activity around the world",
      thumb:
        "https://www.telegraph.co.uk/content/dam/PortalPictures/June2019/mayputin858-small.jpg?imwidth=120"
    },
    {
      id: "xx",
      title:
        "Theresa May calls on Vladimir Putin to halt irresponsible activity around the world",
      thumb:
        "https://www.telegraph.co.uk/content/dam/PortalPictures/June2019/mayputin858-small.jpg?imwidth=120"
    }
  ];

  return (
    <section id="activity">
      <Card full>
        <Card.Header
          title={
            <>
              <h2 className="group__headline">Notifications</h2>
              <button className="btn btn--tiny-text">See all</button>
            </>
          }
        />
        <Card.Body>
          <ul className="saved-list">
            <li>
              A. Reader has replied to your{" "}
              <a className="activity__link" href="#">
                comment
              </a>
            </li>
            <li>
              Boris Johnson has written a{" "}
              <a className="activity__link" href="#">
                new article
              </a>
            </li>
            <li>
              Watch our documentary{" "}
              <a className="activity__link" href="#">
                The Disk
              </a>
            </li>
          </ul>
        </Card.Body>
      </Card>

      <WhiteSpace />

      <div className="activity__history">
        <WingBlank className="activity__history-header">
          <h2 className="group__headline">Article history</h2>
          <button className="btn btn--tiny-text">See all</button>
        </WingBlank>
        <Carousel
          className="activity__history-carousel"
          slideWidth={0.8}
          dots={false}
          cellSpacing={10}
          // swipeSpeed={70}
        >
          <div className="activity__history-slide">
            <img
              className="activity__history-image"
              src="https://www.telegraph.co.uk/content/dam/money/2019/04/10/TELEMMGLPICT000170178359-small_trans_NvBQzQNjv4BqpVlberWd9EgFPZtcLiMQfyf2A9a6I9YchsjMeADBa08.jpeg?imwidth=120"
            />
            <p className="activity__history-text">
              The ultimate guide to retiring young: five essential tips to enjoy
              the later years
            </p>
          </div>

          <div className="activity__history-slide">
            <img
              className="activity__history-image"
              src="https://www.telegraph.co.uk/content/dam/money/2019/04/10/TELEMMGLPICT000170178359-small_trans_NvBQzQNjv4BqpVlberWd9EgFPZtcLiMQfyf2A9a6I9YchsjMeADBa08.jpeg?imwidth=120"
            />
            <p className="activity__history-text">
              The ultimate guide to retiring young: five essential tips to enjoy
              the later years
            </p>
          </div>

          <div className="activity__history-slide">
            <img
              className="activity__history-image"
              src="https://www.telegraph.co.uk/content/dam/money/2019/04/10/TELEMMGLPICT000170178359-small_trans_NvBQzQNjv4BqpVlberWd9EgFPZtcLiMQfyf2A9a6I9YchsjMeADBa08.jpeg?imwidth=120"
            />
            <p className="activity__history-text">
              The ultimate guide to retiring young: five essential tips to enjoy
              the later years
            </p>
          </div>

          <div className="activity__history-slide">
            <img
              className="activity__history-image"
              src="https://www.telegraph.co.uk/content/dam/money/2019/04/10/TELEMMGLPICT000170178359-small_trans_NvBQzQNjv4BqpVlberWd9EgFPZtcLiMQfyf2A9a6I9YchsjMeADBa08.jpeg?imwidth=120"
            />
            <p className="activity__history-text">
              The ultimate guide to retiring young: five essential tips to enjoy
              the later years
            </p>
          </div>

          <div className="activity__history-slide">
            <img
              className="activity__history-image"
              src="https://www.telegraph.co.uk/content/dam/money/2019/04/10/TELEMMGLPICT000170178359-small_trans_NvBQzQNjv4BqpVlberWd9EgFPZtcLiMQfyf2A9a6I9YchsjMeADBa08.jpeg?imwidth=120"
            />
            <p className="activity__history-text">
              The ultimate guide to retiring young: five essential tips to enjoy
              the later years
            </p>
          </div>
        </Carousel>
      </div>

      <WhiteSpace />

      <WingBlank>
        <List
          className="list list--clean"
          renderHeader={
            <>
              <h2 className="group__headline">Saved</h2>
              <button className="btn btn--tiny-text">See all</button>
            </>
          }
        >
          {savedData.map(
            (item): JSX.Element => {
              return (
                <Item
                  key={item.id}
                  thumb={item.thumb}
                  multipleLine
                  wrap
                  align="top"
                  className="list-item--clean"
                >
                  <p className="list-item--text">{item.title}</p>
                </Item>
              );
            }
          )}
        </List>
      </WingBlank>
    </section>
  );
};

export default ActivityView;
