import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { StateInterface } from "reducers/rootReducer";
import { EditionInterface, EditionTypes } from "actions/EditionActions";
import { setActiveEdition, setViewMode } from "actions/ConfigActions";
import { EditionCover } from "components/EditionCover/EditionCover";
import "./NewsStandView.css";
import { ViewModes } from "reducers/configReducer";

interface PropsInterface extends MapStatePropsInterface, MapDispatchInterface {}

class NewsStandView extends PureComponent<PropsInterface> {
  public chooseEdition(editionId: string): void {
    const { setActiveEdition, setViewMode } = this.props;
    setActiveEdition(editionId);
    setViewMode(ViewModes.INDEX);
  }

  public render(): JSX.Element {
    const { editions } = this.props;

    return (
      <section className="news_stand">
        {editions.map(
          (edition: EditionInterface): JSX.Element => (
            <EditionCover
              date={edition.published}
              handleSelect={(): void => {
                this.chooseEdition(edition.edition_id);
              }}
              key={edition.edition_id}
            />
          )
        )}
      </section>
    );
  }
}

interface MapStatePropsInterface {
  editions: EditionInterface[];
}

function mapStateToProps(state: StateInterface): MapStatePropsInterface {
  const EDITION_COUNT = 5;
  const filteredEditions = Object.values(state.editions)
    .filter((e): boolean => e.type === EditionTypes.DAILY)
    .sort((a, b): number => Date.parse(b.published) - Date.parse(a.published))
    .slice(0, EDITION_COUNT);
  return {
    editions: filteredEditions
  };
}

interface MapDispatchInterface {
  setActiveEdition: typeof setActiveEdition;
  setViewMode: typeof setViewMode;
}

export default connect(
  mapStateToProps,
  { setActiveEdition, setViewMode }
)(NewsStandView);
